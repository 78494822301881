angular.module('brandflyg')
    .controller('detailsCtrl', [
        '$scope',
        '$rootScope',
        '$state',
        'Report',
        function ($scope, $rootScope, $state, Report) {
            $rootScope.state = 2;
            $scope.report = Report;

            $scope.continue = function(report){
                $state.go('fires');
            };

        }
    ]);