angular.module('brandflyg')
    .factory('Report', ['$http', function ($http) {

        var Report = {
            fires : []
        };

        Report.fromFlight = function(flight){
            this.mwl_flight_id = flight.flightID;
            this.date = new Date(flight.flightDate);

            var takeoff = flight.airborneStart.split(':');
            this.takeoff = new Date(Date.UTC(1970, 1, 1, takeoff[0], takeoff[1]));

            var landing = flight.airborneEnd.split(':');
            this.landing = new Date(Date.UTC(1970, 1, 1, landing[0], landing[1]));

            this.pilot = flight.flightUserFullname;
            this.lookout = flight.flightScoutFullname;
            this.departure = flight.departurePlace;
            this.arrival = flight.arrivalPlace;
            this.route = 0;
            this.aircraft =  flight.regnr;
            this.tacho = flight.tachTotal;
        };

        Report.save = function(){
            return $http.post('reports', this);
        };

        return Report;
    }]);