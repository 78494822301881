angular.module('brandflyg')
.controller('reviewCtrl', [
        '$scope',
        '$rootScope',
        '$state',
        'Report',
        function($scope, $rootScope, $state, Report){
            $scope.report = Report;
            $rootScope.state = 4;

            $scope.save = function(report){
                report.save().then(function(){
                    $rootScope.state = 5;
                    $state.go('confirmation');
                });
            };
        }
    ]);