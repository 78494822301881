angular.module('brandflyg')
    .controller('firesCtrl', [
        '$scope',
        '$rootScope',
        '$state',
        'Report',
        'rescuers',
        function ($scope, $rootScope, $state, Report, rescuers) {

            $rootScope.state = 3;
            $scope.rescuers = rescuers.data;
            $scope.report = Report;

            $scope.continue = function () {
                $state.go('review');
            };

            $scope.addFire = function () {
                $scope.report.fires.push({});
            };

            $scope.deleteFire = function (index) {
                $scope.report.fires.splice(index, 1);
                return false;
            };
        }
    ]);