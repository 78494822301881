angular.module('brandflyg', ['ui.bootstrap', 'ui.router'])
    .config([
        '$stateProvider',
        '$urlRouterProvider',
        '$locationProvider',
        '$compileProvider',
        function ($stateProvider, $urlRouterProvider, $locationProvider, $compileProvider) {

            $locationProvider.html5Mode(true);
            $compileProvider.debugInfoEnabled(false);

            $stateProvider
                .state('flights', {
                    url: '/',
                    views : {
                        action : {
                            templateUrl: 'brandflyg/partials/flights.html',
                            controller: 'flightsCtrl',
                            resolve: {
                                flights: ['$http', function ($http) {
                                    return $http.get('flights').success(function (flights) {
                                        return flights;
                                    });
                                }]
                            }
                        },
                        help : {
                            templateUrl: 'brandflyg/partials/help-flights.html'
                        }
                    }

                })
                .state('details', {
                    //url: '/details',
                    views: {
                        action: {
                            templateUrl: 'brandflyg/partials/flight-details.html',
                            controller: 'detailsCtrl'
                        },
                        help: {
                            templateUrl: 'brandflyg/partials/help-details.html'
                        }
                    }

                })
                .state('fires', {
                    //url: '/fires',
                    views: {
                        action: {
                            templateUrl: 'brandflyg/partials/flight-fires.html',
                            controller: 'firesCtrl',
                            resolve: {
                                rescuers: ['$http', function ($http) {
                                    return $http.get('rescuers', {cache:true}).success(function (rescuers) {
                                        return rescuers;
                                    });
                                }]
                            }
                        },
                        help: {
                            templateUrl: 'brandflyg/partials/help-fires.html'
                        }
                    }
                })
                .state('review', {
                    //url: '/review',
                    views: {
                        action: {
                            templateUrl: 'brandflyg/partials/review.html',
                            controller: 'reviewCtrl'
                        },
                        help: {
                            templateUrl: 'brandflyg/partials/help-review.html'
                        }
                    }
                })
                .state('confirmation', {
                    //url: '/confirmation',
                    views: {
                        action: {
                            templateUrl: 'brandflyg/partials/confirmation.html'
                        },
                        help: {
                            template: ''
                        }
                    }
                })
            ;

        }]);