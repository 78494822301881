angular.module('brandflyg')
    .controller('flightsCtrl',
    [
        '$scope',
        '$rootScope',
        '$state',
        'Report',
        'flights',
        function ($scope, $rootScope, $state, Report, flights) {
            $scope.flights = flights.data;
            $rootScope.state = 1;

            $scope.select = function (flight) {
                Report.fromFlight(flight);
                $state.go('details');
            };
        }]);